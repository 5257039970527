<template>
<div class="login">
  <h1>
    思流
    <img src="@assets/images/logo@2x.png" alt="思流" class="logo">
  </h1>
  <el-row class="row">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="12"
      :xl="12"
      class="leftBlock"
    >
      <h2 class="slogan">一个办公效能大数据的AI分析平台</h2>
      <el-image class="image" :src="require('@assets/images/img_login.png')" />
    </el-col>
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="{span: 9, offset: 2}"
      :xl="{span: 9, offset: 2}"
    >
      <div class="form">
        <p class="form-title">欢迎您</p>
        <p class="form-sub-title">使用思流数据看板</p>
        <el-form class="field" :model="form" :rules="rules" ref="form">
          <el-form-item prop="userName" class="input">
            <el-input
              type="text"
              v-model="form.userName"
              placeholder="请输入用户名"
            >
              <template #prefix>
                <i class="iconfont icon-user"></i>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="password" class="input">
            <el-input
              type="password"
              v-model="form.password"
              placeholder="请输入密码"
              show-password
            >
              <template #prefix>
                <i class="iconfont icon-password"></i>
              </template>
            </el-input>
          </el-form-item>
          <el-button
            class="button"
            type="primary"
            @click="login"
            :loading="submitting"
            native-type="submit"
          >
            登录
          </el-button>
        </el-form>
      </div>
    </el-col>
  </el-row>
</div>
</template>

<script>
import { get } from 'lodash';
import * as API from '@constants/apis';
import { SESSION_STORAGE_KEYS } from '@constants/storage';


export default {
  name: 'Login',
  data() {
    return {
      submitting: false,
      form: {
        userName: '',
        password: '',
      },
      rules: {
        userName: [{
            required: true,
            message: '请输入用户名',
            trigger: 'blur'
          }
        ],
        password: [{
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          },
          {
            min: 6,
            max: 18,
            message: '长度在6到18个字符',
            trigger: 'blur'
          }
        ],
      }
    }
  },
  methods: {
    async login(e) {
      e.preventDefault();
      try {
        this.submitting = true;
        const isFormValid = await this.$refs.form.validate();

        if (!isFormValid) { return; }

        const requestBody = {
          key: this.form.userName,
          password: this.form.password
        };
        const res = await this.$httpRequest.post(API.LOGIN, requestBody);
        const profile = {
          username: get(res, ['data', 'admin', 'name']),
          avatar: get(res, ['data', 'admin', 'avatar']),
        };
        sessionStorage.setItem(SESSION_STORAGE_KEYS.tokenName, get(res, ['data', 'tokenInfo', 'tokenName']));
        sessionStorage.setItem(SESSION_STORAGE_KEYS.token, get(res, ['data', 'tokenInfo', 'tokenValue']));
        sessionStorage.setItem(SESSION_STORAGE_KEYS.tenantId, get(res, ['data', 'admin', 'tenantId']));
        sessionStorage.setItem(SESSION_STORAGE_KEYS.profile, JSON.stringify(profile));
        this.$store.dispatch('auth/login', profile)
        this.$router.replace('/');
      } catch (err) {
        console.log(err);
      } finally {
        this.submitting = false;
      }
    }
  }
}
</script>

<style lang="scss">
.login {
  padding: 40px;
  min-height: 100%;
  background-color: $color-white;
  h1 {
    font-size: 0;
    .logo {
      width: 60px;
      height: auto;
    }
  }
  .row {
    max-width: 1536px;
    margin: 100px auto 0!important;
  }

  .leftBlock {
    @include flex-center(column);
  }

  .image {
    max-width: 784px;
    height: auto;
  }

  .slogan {
    margin-bottom: 60px;
    color: $color-text-primary;
    font-weight: 500;
    font-size: 42px;
    text-align: center;
  }

  .form-title {
    margin-bottom: 30px;
    color: $color-text-primary;
    font-size: 30px;
  }

  .form-sub-title {
    color: $color-text-primary;
    font-size: 24px;
  }

  .form {
    padding: 100px 0;
    @include flex-center(column);
    border-radius: 20px;
    background-color: #F7F7F7;
  }

  .button {
    margin-top: 100px;
    width: 100%;
    height: 60px;
    border-radius: 8px;
    font-size: 22px;
  }

  .field {
    margin-top: 100px;
    width: 320px;
    .input {
      margin-bottom: 30px;
    }
    .iconfont {
      font-size: 20px;
      color: $color-text-primary;
    }
    input {
      border-width: 0 !important;
      border-bottom: 1px solid $color-border-primary !important;
      background-color: transparent;
      @include title-secondary($color-text-primary);
    }
  }
}
</style>
